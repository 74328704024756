<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <page-header
          :title="'Company Info'"
          :sub-heading="true"
          :sub-header="'Dashboard'"
          :link-name="'Payment History'"
          :link-url="'/payment-history'"
        >
          <ca-button :type="'ghost'" @click.native="exportCSV" class="mr-1">Export CSV</ca-button>
          <ca-button @click.native="addNewCompany">Add New</ca-button>
        </page-header>

        <full-page-content-container>
          <ca-popup
            :header="'Delete Company'"
            :toggle="openDeletePopup"
            @open="confirmDelete"
            @close="cancelDelete"
          >
            <template v-slot:content>
              Are you sure you want to delete this Company?
            </template>
            <template v-slot:actions>
              <ca-button @click.native="deleteUser">Yes</ca-button>
            </template>
          </ca-popup>
          <grid-table
            :column-defs="columnDefs"
            :row-data="rowData"
            v-if="rowData.length > 0"
            :fetching-data="fetchingData"
          >
            <template v-slot:cell-renderer="slotProps">
              <div>
                <span
                  class="edit-icon"
                  @click="viewCompanyInfo(slotProps.currentRowData.id)"
                  ><img
                    :src="require('@/assets/images/icons/view.svg')"
                    alt="edit icon"
                /></span>
                <span class="delete-icon"
                  @click="confirmDelete()"
                  ><img
                    :src="require('@/assets/images/icons/delete.svg')"
                    alt="delete icon"
                /></span>
              </div>
            </template>
          </grid-table>
        </full-page-content-container>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../layouts/components/fullPage/PageHeader";
import CaButton from "../../components/buttons/CaButton";
import GridTable from "../../components/tables/GridTable";
// import company_info from "../../../db/company_info";
import FullPageContentContainer from "../../layouts/components/fullPage/FullPageContentContainer";
import CaPopup from "../../components/popup/CaPopup";
import axios from "axios";
export default {
  name: "CompanyInfo",
  components: { FullPageContentContainer, GridTable, CaButton, PageHeader, CaPopup },
  data() {
    return {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Company Name",
          field: "name",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Email Addresses",
          field: "email",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Country",
          field: "country",
          filter: true,
          sortable: true,
        },
        {
          headerName: "City",
          field: "city",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Actions",
          field: "actions",
          hasCellRendererFramework: true,
        },
      ],
      rowData: [],
      cellRendererAction: "cell-renderer-action",
      fetchingData: true,
      openDeletePopup: false,
    };
  },
  methods: {
    viewCompanyInfo(companyId) {
      this.$router.push({
        name: "view-company-info",
        params: { id: companyId },
      });
    },
    addNewCompany() {
      this.$router.push({ name: "add-new-company" });
    },
    showAllCompany() {
      const loading = this.$vs.loading();
      this.fetchingData = true;
      axios.defaults.headers.common["Authorization"] =
        this.$store.state.accessToken;
      axios
        .get(`${this.$store.state.server.requestUri}/admin/show-all-company`)
        .then((res) => {
          const data = res.data.data;
          if (data.company.length > 0) {
            // console.log(data.company[0]);n
            // console.log(data.company.length)
            this.rowData = data.company;
          } else {
            console.log("No company data");
          }
          this.fetchingData = false;
          loading.close();
        })
        .catch((err) => {
          this.fetchingData = false;
          loading.close();
          console.log(err);
        });
    },
    cancelDelete() {
      this.openDeletePopup = false;
    },
    confirmDelete() {
      this.openDeletePopup = true;
    },
    exportCSV(){
      let csvContent = "data:text/csv;charset=utf-8,";
      const date = new Date()
      const FullDate = date.getDate() +'-'+date.getMonth()+'-'+date.getFullYear()+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds()
      const name = "caena-companyinfo-"+FullDate+".csv"
      let rowArray = Object.keys(this.rowData[0])
          let row = rowArray.join(",");
          csvContent += row + "\r\n";
      this.rowData.forEach(function(data) {
          let rowArray = Object.values(data)
          let row = rowArray.join(",");
          csvContent += row + "\r\n";
      });

     var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", name);
      document.body.appendChild(link); 

      link.click(); 
    }
  },
  created() {
    this.showAllCompany();
    // this.rowData = company_info;
  },
};
</script>

<style scoped>
.edit-icon {
  margin-right: 0.8rem;
}

span:hover {
  cursor: pointer;
}
</style>
